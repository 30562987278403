.drawer-sub-link {
    margin-left: 2.5rem !important;
}

.slide-img {
    position: relative;
    width: 100vw;
}
  
.slide-img img {
    display: block;
    width: 100%;
    height: 100vh;
    object-fit: cover;
}
  
.slide-label {
    position: absolute;
    z-index: 1;
    bottom: 0;
    padding: 15vw 15vw 15vw 5vw;
}
  
.slide-img::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background: rgba(0, 0, 0, .4);
}

.carousel-swiper .swiper-pagination {
    text-align: start;
    margin-left: 20px;
}

.carousel-swiper .swiper-pagination-bullet {
    padding: 6px;
    margin-right: 10px !important;
    background: rgba(255, 255, 255, 1)
}

.carousel-swiper .swiper-pagination-bullet-active {
    background: #ffffff;
    padding: 8px;
    margin-right: 10px !important;
}

.review-swiper .swiper-pagination-bullet {
    background: rgba(0, 0, 0, 1)
}

.review-swiper .swiper-pagination-bullet-active {
    background: rgb(65, 64, 64);
}

.review-swiper .swiper-wrapper {
    padding-bottom: 30px;
}

.MuiTabs-scrollButtons.Mui-disabled {
    opacity: 0.3;
}

.MuiTabs-indicator{
    display: none;
}

.swiper-button-prev {
    width: 40px;
    height: 40px;
    color: #183f3d;
    border-radius: 48px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 0 6px 0 #0005;
    margin-left: -6px;
}

.swiper-button-prev::after {
    font-size: 16px;
    font-weight: bold;
}

.swiper-button-next {
    width: 40px;
    height: 40px;
    color: #183f3d;
    border-radius: 48px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 0 6px 0 #0005;
    margin-right: -6px;
}

.swiper-button-next::after {
    font-size: 16px;
    font-weight: bold;
}

.mySwiper2 {
    height: 80%;
    width: 100%;
}

.mySwiper {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
}

.mySwiper .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
    opacity: 1;
}

.mySwiper2 .swiper-slide img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.mySwiper .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.mySwiper3 .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* set font */
/* FuturaPT font-weight: book, medium, demi, heavy, bold */
@font-face {
    font-family: "FuturaPTBook";
    src: url("@fonts/futura-pt/FuturaPTBook.otf") format("opentype");
}
@font-face {
    font-family: "FuturaPTMedium";
    src: url("@fonts/futura-pt/FuturaPTMedium.otf") format("opentype");
}
@font-face {
    font-family: "FuturaPTDemi";
    src: url("@fonts/futura-pt/FuturaPTDemi.otf") format("opentype");
}
@font-face {
    font-family: "FuturaPTHeavy";
    src: url("@fonts/futura-pt/FuturaPTHeavy.otf") format("opentype");
}
@font-face {
    font-family: "FuturaPTBold";
    src: url("@fonts/futura-pt/FuturaPTBold.otf") format("opentype");
}

/* PlayfairDisplay font-weight: regular, medium, bold, black  */
@font-face {
    font-family: "PlayfairDisplayRegular";
    src: url("@fonts/playfair-display/PlayfairDisplay-VariableFont_wght.ttf") format("truetype");
}
@font-face {
    font-family: "PlayfairDisplayItalic";
    src: url("@fonts/playfair-display/PlayfairDisplay-Italic.otf") format("opentype");
}
@font-face {
    font-family: "PlayfairDisplayMedium";
    src: url("@fonts/playfair-display/PlayfairDisplay-Medium.ttf") format("truetype");
}
@font-face {
    font-family: "PlayfairDisplayMediumItalic";
    src: url("@fonts/playfair-display/PlayfairDisplay-MediumItalic.ttf") format("truetype");
}
@font-face {
    font-family: "PlayfairDisplayBold";
    src: url("@fonts/playfair-display/PlayfairDisplay-Bold.otf") format("opentype");
}
@font-face {
    font-family: "PlayfairDisplayBoldItalic";
    src: url("@fonts/playfair-display/PlayfairDisplay-BoldItalic.otf") format("opentype");
}
@font-face {
    font-family: "PlayfairDisplayBlack";
    src: url("@fonts/playfair-display/PlayfairDisplay-Black.otf") format("opentype");
}
@font-face {
    font-family: "PlayfairDisplayBlackItalic";
    src: url("@fonts/playfair-display/PlayfairDisplay-BlackItalic.otf") format("opentype");
}

* {
    font-family: 'FuturaPTBook';
}

@media screen and (max-width: 1200px) {
    * {
      font-size: 16px;
    }
}
@media screen and (max-width: 900px) {
    * {
      font-size: 14px;
    }
}
@media screen and (max-width: 600px) {
    * {
      font-size: 10px;
    }
}